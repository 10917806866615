import { Transition, Menu } from "@headlessui/react";
import { useState, useEffect, useRef } from "react";

import Modal from "./modal";
import Login from "../auth/login";
import config from "../config";
import { check_login, check_ontgroend } from "../auth/auth";
import Logout from "../auth/auth";
import { Link, useLocation } from "react-router-dom";
import { BsPersonFill } from "react-icons/bs";
import { MdOutlineHowToVote, MdOutlinePersonOutline, MdOutlineAdminPanelSettings } from "react-icons/md";
import { RiShutDownLine } from "react-icons/ri";
import { check_permission } from "../auth/auth";
function Navigation() {
    const [account, setAccount] = useState(null);

    const [showSidebar, setShowSidebar] = useState(false);
    const [dropDown, setDropDown] = useState(0);
    const [reRender, setReRender] = useState(0);

    const [loginModal, setLoginModal] = useState(false);

    function updateDarkMode() {
        if (!("theme" in localStorage)) {
            localStorage.theme = "light";
        }
        if (localStorage.theme === "dark" || !("theme" in localStorage)) {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }

    function checkLogin() {
        const logged_in = check_login();
        setLoginModal(false);
        if (!!logged_in) {
            let claims = JSON.parse(logged_in);
            //console.log(claims)
            setAccount({ gebruikersnaam: claims?.first_name, praesidium: claims?.permissions?.praesidium });
        } else {
            setAccount(null);
        }
    }

    //closing nav on ref
    const navRef = useRef();

    useEffect(() => {
        checkLogin();
        function handler(event) {
            if (!navRef.current?.contains(event.target)) {
                setShowSidebar(false);
                setDropDown(0);
            }
        }
        window.addEventListener("click", handler);
        return () => window.removeEventListener("click", handler);
    }, []);

    let location = useLocation();
    useEffect(() => {
        setShowSidebar(false);
        setDropDown(0);
        updateDarkMode();
    }, [location]);

    return (
        <div ref={navRef} className="md:mb-28 mb-24">
            <div className="fixed top-0 w-full z-20 h-24 md:h-28 bg-white dark:bg-zinc-900 flex items-center justify-center shadow">
                <div className="lg:w-8/12 w-11/12 text-zinc-700 dark:text-zinc-400 flex flex-items-center">
                    <div className="flex items-center gap-6 flex-grow">
                        <Link to={"/"} className="col-span-2 md:col-span-2 flex md:justify-center">
                            <img className="w-[4.5rem] p-2 md:p-0" src={config.base_url + "/images/Hermes.svg"} alt="Hermes" />
                        </Link>
                    </div>

                    <div className="flex items-center gap-7">
                        <Menu as="div" className="relative hidden 2xl:block">
                            {({ open }) => (
                                <>
                                    <Menu.Button className="uppercase text-zinc-500 font-semibold dark:text-zinc-400 dark:hover:text-blue-300 transition-colors hover:text-blue-600 flex items-center">
                                        OVER HERMES
                                        <svg xmlns="http://www.w3.org/2000/svg" className={"h-5 w-5 transition-transform " + (open ? "rotate-180" : "")} viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </Menu.Button>
                                    <Menu.Items className="absolute w-64 top-8 -right-[2px] p-4 text-left bg-white shadow-md border border-zinc-200 dark:border-zinc-800 dark:bg-zinc-800 text-zinc-500 dark:text-zinc-400 rounded-lg flex flex-col focus:outline-none">
                                        <div className="absolute w-2 h-2 bg-white dark:bg-zinc-800 dark:border-zinc-800 -top-1 right-2 -rotate-45 border-r border-t border-zinc-200 rounded-sm"></div>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link to="/algemeen" className={`${active && "bg-zinc-100 dark:bg-zinc-900"} transition-colors flex items-center gap-3 p-3 rounded-md`}>
                                                    Algemeen
                                                </Link>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link to="/praesidium" className={`${active && "bg-zinc-100 dark:bg-zinc-900"} transition-colors flex items-center gap-3 p-3 rounded-md`}>
                                                    Praesidium
                                                </Link>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link to="/propraesidia" className={`${active && "bg-zinc-100 dark:bg-zinc-900"} transition-colors flex items-center gap-3 p-3 rounded-md`}>
                                                    Propraesidia
                                                </Link>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link to="/kringlied" className={`${active && "bg-zinc-100 dark:bg-zinc-900"} transition-colors flex items-center gap-3 p-3 rounded-md`}>
                                                    Kringlied
                                                </Link>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link to="/policy" className={`${active && "bg-zinc-100 dark:bg-zinc-900"} transition-colors flex items-center gap-3 p-3 rounded-md`}>
                                                    Privacy policy
                                                </Link>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link to="/statuten" className={`${active && "bg-zinc-100 dark:bg-zinc-900"} transition-colors flex items-center gap-3 p-3 rounded-md`}>
                                                    Statuten
                                                </Link>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                </>
                            )}
                        </Menu>
                        <Menu as="div" className="relative hidden md:block">
                            {({ open }) => (
                                <>
                                    <Menu.Button className="uppercase text-zinc-500 font-semibold dark:text-zinc-400 dark:hover:text-blue-300 transition-colors hover:text-blue-600 flex items-center">
                                        VOOR STUDENTEN
                                        <svg xmlns="http://www.w3.org/2000/svg" className={"h-5 w-5 transition-transform " + (open ? "rotate-180" : "")} viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </Menu.Button>
                                    <Menu.Items className="absolute w-64 top-8 -right-[2px] p-4 text-left bg-white shadow-md border border-zinc-200 dark:border-zinc-800 dark:bg-zinc-800 text-zinc-500 dark:text-zinc-400 rounded-lg flex flex-col focus:outline-none">
                                        <div className="absolute w-2 h-2 bg-white dark:bg-zinc-800 dark:border-zinc-800 -top-1 right-2 -rotate-45 border-r border-t border-zinc-200 rounded-sm"></div>
                                        
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link to="/leden-voordeel" className={`${active && "bg-zinc-100 dark:bg-zinc-900"} transition-colors flex items-center gap-3 p-3 rounded-md`}>
                                                    Leden voordeel
                                                </Link>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link to="/onthaaldag" className={`${active && "bg-zinc-100 dark:bg-zinc-900"} transition-colors flex items-center gap-3 p-3 rounded-md`}>
                                                    Onthaaldag
                                                </Link>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <Link to="/toekomstige-indies" className={`${active && "bg-zinc-100 dark:bg-zinc-900"} transition-colors flex items-center gap-3 p-3 rounded-md`}>
                                                    Toekomstige Indies
                                                </Link>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                </>
                            )}
                        </Menu>
                        <Link to={"/events"} className="hidden sm:block uppercase text-zinc-500 font-semibold dark:text-zinc-400 dark:hover:text-blue-300 transition-colors hover:text-blue-600">
                            ACTIVITEITEN
                        </Link>
                        <Link to={"/cursusverkoop"} className="hidden xs:block uppercase text-zinc-500 font-semibold dark:text-zinc-400 dark:hover:text-blue-300 transition-colors hover:text-blue-600">
                            CURSUSVERKOOP
                        </Link>
                        

                        <Menu as="div" className="relative hidden 2xl:block">
                            {({ open }) => (
                                <>
                                    <Menu.Button className="uppercase text-zinc-500 font-semibold dark:text-zinc-400 dark:hover:text-blue-300 transition-colors hover:text-blue-600 flex items-center">
                                        RECRUITMENT
                                        <svg xmlns="http://www.w3.org/2000/svg" className={"h-5 w-5 transition-transform " + (open ? "rotate-180" : "")} viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                    </Menu.Button>
                                    <Menu.Items className="absolute w-64 top-8 -right-[2px] p-4 text-left bg-white shadow-md border border-zinc-200 dark:border-zinc-800 dark:bg-zinc-800 text-zinc-500 dark:text-zinc-400 rounded-lg flex flex-col focus:outline-none">
                                        <div className="absolute w-2 h-2 bg-white dark:bg-zinc-800 dark:border-zinc-800 -top-1 right-2 -rotate-45 border-r border-t border-zinc-200 rounded-sm"></div>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a target="_blank" className={`${active && "bg-zinc-100 dark:bg-zinc-900"} transition-colors flex items-center gap-3 p-3 rounded-md`} href="https://recruitment.hermesgent.be/student/stages">
                                                    Stages
                                                </a>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a target="_blank" className={`${active && "bg-zinc-100 dark:bg-zinc-900"} transition-colors flex items-center gap-3 p-3 rounded-md`} href="https://recruitment.hermesgent.be/student/jobs">
                                                    Jobs
                                                </a>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a target="_blank" className={`${active && "bg-zinc-100 dark:bg-zinc-900"} transition-colors flex items-center gap-3 p-3 rounded-md`} href="https://recruitment.hermesgent.be/student/currvit">
                                                    Inschrijven
                                                </a>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a target="_blank" className={`${active && "bg-zinc-100 dark:bg-zinc-900"} transition-colors flex items-center gap-3 p-3 rounded-md`} href="https://recruitment.hermesgent.be/student/job-en-stage-event">
                                                    Job- en Stage Event
                                                </a>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                </>
                            )}
                        </Menu>
                        <Link to={"/contact"} className="hidden 3xl:block uppercase text-zinc-500 font-semibold dark:text-zinc-400 dark:hover:text-blue-300 transition-colors hover:text-blue-600">
                            CONTACT
                        </Link>

                        <button onClick={() => setShowSidebar(!showSidebar)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </button>
                        <div className="flex items-center gap-2">
                            {!account ? (
                                <Modal condition={loginModal} setCondition={setLoginModal} openButtonValue="LOGIN" openButtonClasses="font-bold text-blue-600 dark:text-blue-400" boxClasses="px-2 w-full md:w-10/12 lg:w-6/12 xl:w-4/12 md:mx-auto mt-12 lg:mt-36 mb-16">
                                    <Login reload={() => checkLogin()} />
                                </Modal>
                            ) : (
                                <UserNavigation account={account} setAccount={setAccount} />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="fixed top-0 z-30 right-0">
                <Transition show={showSidebar} enter="transition-transform duration-200" enterFrom="translate-x-[32em]" enterTo="translate-x-0" leave="transition-all duration-200" leaveFrom="translate-x-0" leaveTo="translate-x-[32em]">
                    <div className="w-screen md:w-96 h-screen bg-white dark:bg-zinc-900 shadow-lg dark:border-r dark:border-zinc-700 relative">
                        <button className="absolute right-5 top-5 justify-end text-zinc-400 dark:text-zinc-600 transition-colors p-2 rounded-full hover:bg-zinc-100 dark:hover:bg-zinc-700 dark:hover:text-zinc-500" onClick={() => setShowSidebar(false) & setDropDown(0)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                        </button>

                        <div className="h-full flex flex-col gap-1 md:gap-3 lg:gap-6 overflow-y-auto py-20">
                            <div className="2xl:hidden uppercase font-bold text-lg text-zinc-600 dark:text-zinc-400 flex flex-col gap-1 pr-16 text-right">
                                <div onClick={() => (dropDown !== 1 ? setDropDown(1) : setDropDown(0))} className={"flex justify-end items-center gap-1 cursor-pointer py-4 transition-colors " + (dropDown === 1 ? "text-blue-600 dark:text-blue-500" : "hover:text-blue-400")}>
                                    OVER ONS
                                    <svg xmlns="http://www.w3.org/2000/svg" className={"h-5 w-5 transition-transform " + (dropDown === 1 ? "rotate-180" : "")} viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </div>
                                {dropDown === 1 ? (
                                    <div className="py-2 flex flex-col gap-2">
                                        <Link to={"/algemeen"} className="uppercase font-bold text-lg text-zinc-500 transition-colors hover:text-blue-400">
                                            ALGEMEEN
                                        </Link>
                                        <Link to={"/praesidium"} className="uppercase font-bold text-lg text-zinc-500 transition-colors hover:text-blue-400">
                                            PRAESIDIUM
                                        </Link>
                                        <Link to={"/propraesidia"} className="uppercase font-bold text-lg text-zinc-500 transition-colors hover:text-blue-400">
                                            PROPRAESIDIA
                                        </Link>
                                        <div className="uppercase font-bold text-lg text-zinc-500 transition-colors hover:text-blue-400 hidden">TOOGHANGERS</div>
                                        <Link to={"/kringlied"} className="uppercase font-bold text-lg text-zinc-500 transition-colors hover:text-blue-400">
                                            KRINGLIED
                                        </Link>
                                        <Link to={"/policy"} className="uppercase font-bold text-lg text-zinc-500 transition-colors hover:text-blue-400">
                                            Privacy Policy
                                        </Link>
                                        <Link to={"/statuten"} className="uppercase font-bold text-lg text-zinc-500 transition-colors hover:text-blue-400">
                                            Statuten en Reglement
                                        </Link>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="uppercase xs:hidden font-bold text-lg text-zinc-600 dark:text-zinc-400">
                                <Link to={"/cursusverkoop"} className="flex justify-end pr-16 gap-1 cursor-pointer py-4 transition-colors hover:text-blue-400">
                                    CURSUSVERKOOP
                                </Link>
                            </div>
                            <div className="uppercase sm:hidden font-bold text-lg text-zinc-600 dark:text-zinc-400">
                                <Link to={"/events"} className="flex justify-end pr-16 gap-1 cursor-pointer py-4 transition-colors hover:text-blue-400">
                                    Activiteiten
                                </Link>
                            </div>
                            <div className="uppercase font-bold text-lg text-zinc-600 dark:text-zinc-400">
                                <Link to={"/sport"} className="flex justify-end pr-16 gap-1 cursor-pointer py-4 transition-colors hover:text-blue-400">
                                    Sport
                                </Link>
                            </div>
                            <div className="uppercase md:hidden font-bold text-lg text-zinc-600 dark:text-zinc-400 flex flex-col gap-1 pr-16">
                                <div onClick={() => (dropDown !== 2 ? setDropDown(2) : setDropDown(0))} className={"flex justify-end items-center gap-1 cursor-pointer py-4 transition-colors " + (dropDown === 2 ? "text-blue-600 dark:text-blue-500" : "hover:text-blue-400")}>
                                    VOOR STUDENTEN
                                    <svg xmlns="http://www.w3.org/2000/svg" className={"h-5 w-5 transition-transform " + (dropDown === 2 ? "rotate-180" : "")} viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </div>
                                {dropDown === 2 ? (
                                    <div className="py-2 flex flex-col gap-2 text-right">
                                        <Link to={"/leden-voordeel"} className="uppercase font-bold text-lg text-zinc-500 transition-colors hover:text-blue-400">
                                            Leden voordeel
                                        </Link>
                                        <Link to={"/onthaaldag"} className="uppercase font-bold text-lg text-zinc-500 transition-colors hover:text-blue-400">
                                            Onthaaldag
                                        </Link>
                                        <Link to={"/toekomstige-indies"} className="uppercase font-bold text-lg text-zinc-500 transition-colors hover:text-blue-400">
                                            Toekomstige Indies
                                        </Link>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>

                            <div className="uppercase 2xl:hidden font-bold text-lg text-zinc-600 dark:text-zinc-400 flex flex-col pr-16 gap-1 ">
                                <div onClick={() => (dropDown !== 3 ? setDropDown(3) : setDropDown(0))} className={"flex justify-end items-center gap-1 cursor-pointer py-4 transition-colors " + (dropDown === 3 ? "text-blue-600 dark:text-blue-500" : "hover:text-blue-400")}>
                                    RECRUITMENT
                                    <svg xmlns="http://www.w3.org/2000/svg" className={"h-5 w-5 transition-transform " + (dropDown === 3 ? "rotate-180" : "")} viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </div>
                                {dropDown === 3 ? (
                                    <div className="py-2 flex flex-col text-right gap-2">
                                        <a href="https://recruitment.hermesgent.be/student/stages" className="uppercase font-bold text-lg text-zinc-500 transition-colors hover:text-blue-400">
                                            stages
                                        </a>
                                        <a href="https://recruitment.hermesgent.be/student/jobs" className="uppercase font-bold text-lg text-zinc-500 transition-colors hover:text-blue-400">
                                            jobs
                                        </a>
                                        <a href="https://recruitment.hermesgent.be/student/currvit" className="uppercase font-bold text-lg text-zinc-500 transition-colors hover:text-blue-400">
                                            Inschrijven
                                        </a>
                                        <a href="https://recruitment.hermesgent.be/student/job-en-stage-event" className="uppercase font-bold text-lg text-zinc-500 transition-colors hover:text-blue-400">
                                            Job- en stage-event
                                        </a>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="uppercase font-bold text-lg text-zinc-600 dark:text-zinc-400 flex flex-col gap-1 pr-16">
                                <div onClick={() => (dropDown !== 4 ? setDropDown(4) : setDropDown(0))} className={"flex justify-end items-center gap-1 cursor-pointer py-4 transition-colors " + (dropDown === 4 ? "text-blue-600 dark:text-blue-500" : "hover:text-blue-400")}>
                                    EXTERN
                                    <svg xmlns="http://www.w3.org/2000/svg" className={"h-5 w-5 transition-transform " + (dropDown === 4 ? "rotate-180" : "")} viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </div>
                                {dropDown === 4 ? (
                                    <div className="py-2 flex flex-col gap-2 text-right">
                                        <Link to={"/engage"} className="uppercase font-bold text-lg text-zinc-500 transition-colors hover:text-blue-400">
                                            Engage
                                        </Link>
                                        <Link to={"/iaeste"} className="uppercase font-bold text-lg text-zinc-500 transition-colors hover:text-blue-400">
                                            IAESTE
                                        </Link>
                                        <Link to={"/fris"} className="uppercase font-bold text-lg text-zinc-500 transition-colors hover:text-blue-400">
                                            FRIS
                                        </Link>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="uppercase font-bold text-lg text-zinc-600 dark:text-zinc-400">
                                <Link to={"/sponsors"} className="flex justify-end pr-16 gap-1 cursor-pointer py-4 transition-colors hover:text-blue-400">
                                    SPONSORS
                                </Link>
                            </div>
                            <div className="uppercase 3xl:hidden font-bold text-lg text-zinc-600 dark:text-zinc-400">
                                <Link to={"/contact"} className="flex justify-end pr-16 gap-1 cursor-pointer py-4 transition-colors hover:text-blue-400">
                                    CONTACT
                                </Link>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );
}

export default Navigation;

export function UserNavigation({ account, setAccount }) {
    const [reRender, setReRender] = useState(0);

    const [loginModal, setLoginModal] = useState(false);

    function updateDarkMode() {
        if (!("theme" in localStorage)) {
            localStorage.theme = "light";
        }
        if (localStorage.theme === "dark" || !("theme" in localStorage)) {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }

    function toggleDarkmode() {
        if ("theme" in localStorage) {
            if (localStorage.theme === "dark") {
                localStorage.theme = "light";
                setReRender(reRender + 1);
                return;
            }
            localStorage.theme = "dark";
        } else {
            localStorage.theme = "dark";
        }
        setReRender(reRender + 1);
    }
    useEffect(() => {
        updateDarkMode();
    }, [reRender]);
    return (
        <Menu as="div" className="relative">
            {({ open }) => (
                <>
                    <Menu.Button className="font-bold text-blue-600 dark:text-blue-400 flex items-center cursor-pointer focus:outline-none">
                        <div className="w-7 h-7 rounded-full bg-blue-200 border-2 border-blue-300 dark:bg-blue-600 dark:border-blue-400 dark:text-zinc-200 flex items-center justify-center">{account.gebruikersnaam.toUpperCase().slice(0, 1)}</div>

                        <svg xmlns="http://www.w3.org/2000/svg" className={"h-5 w-5 transition-transform " + (open ? "rotate-180" : "")} viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </Menu.Button>
                    <Menu.Items className="absolute w-64 top-8 -right-[2px] p-4 text-left bg-white shadow-md border border-zinc-200 dark:border-zinc-800 dark:bg-zinc-800 text-zinc-500 dark:text-zinc-400 rounded-lg flex flex-col focus:outline-none">
                        <div className="absolute w-2 h-2 bg-white dark:bg-zinc-800 dark:border-zinc-800 -top-1 right-2 -rotate-45 border-r border-t border-zinc-200 rounded-sm"></div>
                        <Menu.Item>
                            {({ active }) => (
                                <Link className={`${active && "bg-zinc-100 dark:bg-zinc-900"} transition-colors flex items-center gap-3 p-3 rounded-md`} to="/mijn-gegevens">
                                    <MdOutlinePersonOutline className="w-5 h-5" /> Mijn gegevens
                                </Link>
                            )}
                        </Menu.Item>
                        {check_ontgroend(false) && (
                            <Menu.Item>
                                {({ active }) => (
                                    <Link className={`${active && "bg-zinc-100 dark:bg-zinc-900"} transition-colors flex items-center gap-3 p-3 rounded-md`} to="/stem-module">
                                        <MdOutlineHowToVote className="w-5 h-5" /> Stem module
                                    </Link>
                                )}
                            </Menu.Item>
                        )}
                        {check_permission("praesidium", false) && (
                            <Menu.Item>
                                {({ active }) => (
                                    <Link to={"/paneel"} className={`${active && "bg-zinc-100 dark:bg-zinc-900"} transition-colors flex items-center gap-3 p-3 rounded-md`}>
                                        <MdOutlineAdminPanelSettings className="w-5 h-5" /> Paneel
                                    </Link>
                                )}
                            </Menu.Item>
                        )}

                        <hr className="dark:border-zinc-700 my-1" />
                        <Menu.Item onClick={() => toggleDarkmode()}>
                            {({ active }) => (
                                <div className={`${active && "bg-zinc-100 dark:bg-zinc-900"} transition-colors cursor-pointer flex items-center gap-3 p-3 rounded-md`}>
                                    {(localStorage.theme !== "dark" || !("theme" in localStorage)) && (
                                        <>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
                                            </svg>{" "}
                                            Donkere modus
                                        </>
                                    )}
                                    {localStorage.theme === "dark" && (
                                        <>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
                                            </svg>{" "}
                                            Lichte modus
                                        </>
                                    )}
                                </div>
                            )}
                        </Menu.Item>
                        <Menu.Item onClick={() => setAccount(null)}>
                            {({ active }) => (
                                <button
                                    onClick={() => {
                                        Logout();
                                    }}
                                    className={`${active && "bg-zinc-100 dark:bg-zinc-900"} transition-colors flex items-center gap-3 p-3 rounded-md`}>
                                    <RiShutDownLine className="w-5 h-5" /> Logout
                                </button>
                            )}
                        </Menu.Item>
                    </Menu.Items>
                </>
            )}
        </Menu>
    );
}
