import React from "react";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
import { SiDiscord } from "react-icons/si";
import { useState } from "react";
import axios from "axios";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import { Sponsor } from "../modules/types";
import { _ } from "../modules/functions";
function Footer() {
    const [hoofdSponsors, setHoofdSponsors] = useState<Sponsor[]>([]);
    const [sponsors, setSponsors] = useState<Sponsor[]>([]);

    useEffect(() => {
        Aos.init();
    });
    let RESPONSIVE_OPT_HOOFDSPONSORS = {
        0: {
            items: 1
        },
        600: {
            items: hoofdSponsors.length > 2 ? 2 : hoofdSponsors.length
        },
        1000: {
            items: hoofdSponsors.length > 3 ? 3 : hoofdSponsors.length
        }
    };

    let RESPONSIVE_OPT_SPONSORS = {
        0: {
            items: sponsors.length > 2 ? 2 : sponsors.length
        },
        600: {
            items: sponsors.length > 6 ? 6 : sponsors.length
        },
        1000: {
            items: sponsors.length > 8 ? 8 : sponsors.length
        }
    };

    useEffect(() => {
        axios
            .get("https://hrbackend.hermesgent.be/companies?company_role=head", {
                headers: {
                    Authorization: ""
                }
            })
            .then((response) => {
                setHoofdSponsors(_.SHUFFEL_ARRAY(response.data.filter((Bedrijf: Sponsor) => Bedrijf.active)));
            })
            .catch(() => {});
        axios
            .get("https://hrbackend.hermesgent.be/companies?company_role=recr", {
                headers: {
                    Authorization: ""
                }
            })
            .then((response) => {
                setSponsors(_.SHUFFEL_ARRAY(response.data.filter((Bedrijf: Sponsor) => Bedrijf.active)));
            })
            .catch(() => {});
    }, []);

    return (
        <div>
            <div className="px-16 bg-white py-8">
                {hoofdSponsors.length > 0 ? (
                    <OwlCarousel className="owl-theme flex items-center justify-center" autoplay loop margin={10} responsive={RESPONSIVE_OPT_HOOFDSPONSORS} center autoplayHoverPause dots={false} autoplaySpeed={1400}>
                        {hoofdSponsors.map((sponsor) => {
                            return (
                                <div key={sponsor.id} className="h-32 flex items-center justify-center">
                                    <Link to={"/sponsor/" + sponsor.company_name.replace(/\s+/g, "-").toLowerCase() + "/" + sponsor.id} className="lg:px-8">
                                        <img alt={sponsor.company_name} className="w-full max-w-xs flex max-h-28" src={sponsor.company_logo} />
                                    </Link>
                                </div>
                            );
                        })}
                    </OwlCarousel>
                ) : (
                    ""
                )}
                {sponsors.length > 0 ? (
                    <OwlCarousel className="owl-theme flex items-center justify-center" autoplay loop margin={10} slideBy={3} responsive={RESPONSIVE_OPT_SPONSORS} autoplayHoverPause dots={false} autoplaySpeed={1000}>
                        {sponsors.map((sponsor) => {
                            return (
                                <div key={sponsor.id} className="h-32 flex items-center justify-center">
                                    <Link to={"/sponsor/" + sponsor.company_name.replace(/\s+/g, "-").toLowerCase() + "/" + sponsor.id} className="lg:px-4 xl:px-8">
                                        <img alt={sponsor.company_name} className="w-full max-w-xs flex max-h-28" src={sponsor.company_logo} />
                                    </Link>
                                </div>
                            );
                        })}
                    </OwlCarousel>
                ) : (
                    ""
                )}
            </div>
            <div data-aos="fade" data-aos-duration="800" className="border-zinc-100 border-t dark:border-zinc-800 dark:bg-zinc-900">
                <div className="lg:w-8/12 w-11/12 py-20 mx-auto grid gap-10 lg:gap-0 lg:grid-cols-3">
                    <div className="text-zinc-700 dark:text-zinc-300 text-center lg:text-left mt-[-12px]">
                        <p>lokaal GSCHC.0.170</p>
                        <p>Valentin Vaerwyckweg 1</p>
                        <p>9000 Gent</p>
                        <br />
                        <p>
                            <strong>BTW:</strong> BE0564.730.040
                        </p>
                        <p>
                            <strong>OR:</strong> 564.730.040
                        </p>
                    </div>
                    <div className="font-bold flex flex-col items-center order-first lg:order-none">
                        <div className="text-zinc-300 dark:text-zinc-600 flex gap-2 mb-6">
                            <a href="https://www.linkedin.com/company/hermesgentvzw/" className="transition-colors hover:text-zinc-400 dark:hover:text-zinc-500">
                                <FaLinkedin className="w-7 h-7" />
                            </a>
                            <a href="https://www.facebook.com/Hermesgent/" className="transition-colors hover:text-zinc-400 dark:hover:text-zinc-500">
                                <FaFacebookF className="w-7 h-7" />
                            </a>
                            <a href="https://www.instagram.com/hermesgent/" className="transition-colors hover:text-zinc-400 dark:hover:text-zinc-500">
                                <FaInstagram className="w-7 h-7" />
                            </a>
                            <a href="https://discord.hermesgent.be/" className="transition-colors hover:text-zinc-400 dark:hover:text-zinc-500">
                                <SiDiscord className="w-7 h-7" />
                            </a>
                        </div>
                        <div className="text-blue-700 dark:text-blue-400 text-center">Copyright © Hermes Gent vzw 2022</div>
                    </div>
                    <div className="text-center lg:text-right text-zinc-700 dark:text-zinc-300">
                        <p>Hermes Gent vzw</p>
                        <p className="font-bold">BE17 7330 0451 9321</p>
                        <p>Recruitment</p>
                        <p className="font-bold">BE49 7370 2547 5071</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
