import React, { useEffect, useState } from "react";
import Navigation from "../components/navigation";
import { Helmet } from "react-helmet";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import { Event } from "../modules/types";
import axios from "axios";
import config from "../config";
import Card from "../components/Card";
import DateFormat from "../components/dateFormater";

interface SportActiviteiten {
    loading: boolean;
    error: boolean;
    data: Event[];
}

export default function Sport() {
    let carouselImages = ["https://i.imgur.com/xSpG7tU.jpg", "https://i.imgur.com/54UeoYH.jpg", "https://i.imgur.com/AKUajhL.jpg", "https://i.imgur.com/UMqvOyi.jpg", "https://i.imgur.com/pN1s3rA.jpg"];
    let RESPONSIVE = {
        0: {
            items: carouselImages.length > 1 ? 1 : carouselImages.length
        },
        600: {
            items: carouselImages.length > 2 ? 2 : carouselImages.length
        },
        1000: {
            items: carouselImages.length > 3 ? 3 : carouselImages.length
        }
    };
    const [sportActiviteiten, setSportActiviteiten] = useState<SportActiviteiten>({
        loading: true,
        error: false,
        data: []
    });

    useEffect(() => {
        let params = {
            type: "spo",
            approved: true,
            sort: "start"
        };
        axios
            .get(config.api_url + "/events/", { params: params })
            .then((response) => {
                let data = response.data.data;
                let pushData: Event[] = [];
                let now = new Date();
                for (let i = 0; i < data.length; i++) {
                    let thisEvent = data[i];
                    if (new Date(thisEvent.end) > now) {
                        pushData.push(thisEvent);
                    }
                }
                setSportActiviteiten({
                    loading: false,
                    error: false,
                    data: pushData.splice(0, 3)
                });
            })
            .catch(() => {
                setSportActiviteiten({
                    loading: false,
                    error: true,
                    data: []
                });
            });
    }, []);

    return (
        <div className="dark:bg-zinc-900">
            <Navigation />
            <Helmet>
                <title>Sport - Hermes Gent</title>
                <meta name="description" content={"Hermes Gent - Sport"} />
            </Helmet>

            <div className="lg:w-8/12 w-11/12 pt-20 pb-40 mx-auto">
                <div data-aos="fade-up" data-aos-duration="800" className={"prose prose-zinc dark:prose-invert max-w-full prose-a:no-underline prose-a:text-blue-700 dark:prose-a:text-blue-300 prose-h2:text-3xl"}>
                    <h1>Sport</h1>
                    <p>
                        <strong>Voor alle sportevenementen groot of klein, moet je bij Hermes zijn!</strong>
                    </p>
                    <div>
                        <p>
                            <em>Wat doen wij zoal? </em>
                        </p>
                        <p>
                            Jaarlijks nemen wij deel aan de interfacultaire kampioenschappen en tornooien, maar daarnaast organiseren wij zelf ook leuke sportactiviteiten. Ook nemen wij natuurlijk deel aan de 12-urenloop! Daar is het nog niet gedaan want in de lesvrije week gaan wij ook nog eens
                            naar het verre Frankrijk om te ski&euml;n of snowboarden.
                        </p>
                        <p>
                            <OwlCarousel responsive={RESPONSIVE} className="owl-theme flex items-center justify-center" autoplay loop center dots={true} autoplaySpeed={1400}>
                                {carouselImages.map((image) => {
                                    return (
                                        <div key={image} className="flex items-center justify-center">
                                            <img alt={"Hermes Sport Activiteit"} className="w-full flex h-64 object-cover" src={image} />
                                        </div>
                                    );
                                })}
                            </OwlCarousel>
                        </p>
                        {sportActiviteiten.data.length > 0 && (
                            <p>
                                <h2 className="hidden md:block">Aankomende sport activiteiten</h2>
                                <div className="grid-cols-1 md:gap-4 md:grid-cols-2 xl:grid-cols-3 hidden md:grid">
                                    {sportActiviteiten.data.map((event, index) => {
                                        return (
                                            <Card
                                                className={"not-prose"}
                                                key={index}
                                                foto={event.image}
                                                titel={event.title}
                                                onderschrift={<DateFormat date={event.start} format={"$WD$ $d$ $MM$"} trySpecial={true} styles="text-zinc-600 dark:text-zinc-400 italic capitalize" />}
                                                text={event.short_desc}
                                                // @ts-ignore
                                                link={"/event/" + event.title.replace(/\s+/g, "-").toLowerCase() + "/" + event.id}
                                                knop={
                                                    <>
                                                        MEER INFO
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block ml-2 group-hover:text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                                                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                                        </svg>
                                                    </>
                                                }
                                            />
                                        );
                                    })}
                                </div>
                                {sportActiviteiten.data.length > 3 && (
                                    <p className="w-full flex justify-center my-16">
                                        <Link to="/events/sport" className="border-2 border-blue-700 px-4 py-3 rounded-md font-bold transition-colors hover:bg-blue-700 hover:text-white dark:border-blue-300 dark:hover:bg-blue-400 dark:hover:text-blue-800">
                                            BEKIJK ALLE SPORT ACTIVITEITEN
                                        </Link>
                                    </p>
                                )}
                            </p>
                        )}

                        <h3>Wat houden die leuke sportactiviteiten van Hermes in?</h3>
                        <p>
                            Dit kan gaan van kayak, baanwielrennen tot paintballen en lasershooten aan heel democratische prijzen.&nbsp;
                            <br />
                            Verder is er ook de 12-urenloop.&nbsp; Een estafetteloop van 12 uur lang, jaja, 12 uur spurten op het Sint-Pietersplein! Kei tof, aangezien duizenden supporters je toejuichen. Dus ben jij een goede spurter, dan verwachten we jou zeker die dag op het Sint-Pietersplein.
                            Loop je minder graag, kom dan af voor goedkoop bier en bickyburgers!
                        </p>
                        <div className="grid lg:grid-cols-2 gap-4 mt-8">
                            <div className="shadow-lg rounded-lg p-8 border border-zinc-200 dark:border-zinc-700">
                                <h3 className="mt-0">IFK (interfacultair kampioenschap):</h3>

                                <p>
                                    Dit zijn wedstrijden over verschillende weken waarbij verschillende faculteiten zich bewijzen de beste te zijn in oftewel voetbal, basketbal, badminton, ...&nbsp; Daarom zijn wij steeds op zoek naar gemotiveerde atleten die het competitieve in zichzelf naar boven
                                    willen halen!
                                </p>
                            </div>

                            <div className="shadow-lg rounded-lg p-8 border border-zinc-200 dark:border-zinc-700">
                                <h3 className="mt-0">IFT (interfacultair tornooi):</h3>

                                <p>IFT is een dag waarbij we opnieuw strijden om de beste faculteit te zijn van de UGent in het voetbal, volleybal, basketbal, tafeltennis en zwemaflossing.</p>
                            </div>
                        </div>
                        <table className="my-12">
                            <thead className="bg-blue-200 text-zinc-900 font-bold rounded">
                                <tr>
                                    <td className="p-2">HERMES</td>
                                    <td className="p-2">IFK</td>
                                    <td className="p-2">IFT</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="p-2">Kayak</td>
                                    <td className="p-2">Badminton</td>
                                    <td className="p-2">Voetbal</td>
                                </tr>
                                <tr>
                                    <td className="p-2">Baanwielrennen</td>
                                    <td className="p-2">Basketbal</td>
                                    <td className="p-2">Volleybal</td>
                                </tr>
                                <tr>
                                    <td className="p-2">Karting</td>
                                    <td className="p-2">Voetbal</td>
                                    <td className="p-2">Basketbal</td>
                                </tr>
                                <tr>
                                    <td className="p-2">Muurklimmen</td>
                                    <td className="p-2">Mini-voetbal</td>
                                    <td className="p-2">Tafeltennis</td>
                                </tr>
                                <tr>
                                    <td className="p-2">Pool</td>
                                    <td className="p-2">Volleybal</td>
                                    <td className="p-2">Zwemaflossing</td>
                                </tr>
                                <tr>
                                    <td className="p-2">Lasershoot</td>
                                    <td className="p-2">Zwemmen</td>
                                    <td className="p-2">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td className="p-2">Paintball</td>
                                    <td className="p-2">Tafeltennis</td>
                                    <td className="p-2">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td className="p-2">Padel</td>
                                    <td className="p-2">Watersportbaanloop</td>
                                    <td className="p-2">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td className="p-2">Skireis</td>
                                    <td className="p-2">E-sports</td>
                                    <td className="p-2">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td className="p-2">&nbsp;</td>
                                    <td className="p-2">Judo</td>
                                    <td className="p-2">&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>

                        <p className="text-blue-500 italic mt-8">
                            <span>Blink jij uit in &eacute;&eacute;n van deze sporten of doe je gewoon graag mee? Twijfel niet om je op te geven voor &eacute;&eacute;n van deze teams, misschien wel meerdere voor de sporten die jou interesseren!</span>
                        </p>
                        <p className="mt-8">
                            <span>
                                <strong>&rArr; contacteer &eacute;&eacute;n van de sportjes of join de sport groep </strong>
                            </span>
                            <strong>
                                &nbsp;
                                <br />
                            </strong>
                            <strong>
                                <a href="https://www.facebook.com/groups/1084404811721228/?source=unknown/" target="_blank" rel="noopener">
                                    https://www.facebook.com/groups/1084404811721228/
                                </a>
                            </strong>
                        </p>

                        {/* <h3>IFT/IFK-data.</h3>
                        <p>16/10: IFK Volleybal Dames</p>
                        <p>23/10: IFK Volleybal Heren</p>
                        <p>30/10: IFK Tafeltennis</p>
                        <p>6/11: IFK Watersportbaanloop</p>
                        <p>13/11: IFK Minivoetbal Heren</p>
                        <p>14/11: IFK Judo</p>
                        <p>22/11: IFK Basketbal Dames</p>
                        <p>27/11: IFK Veldvoetbal Heren</p>
                        <p>27/11: IFK Minivoetbal Dames</p>
                        <p>6/12: Zwemmarathon</p>
                        <p>11/12: IFK Badminton</p>
                        <p>14/02: IFK Basketbal Heren</p>

                        <hr /> */}

                        <h3>IFT/IFK achievements</h3>
                        <p>IFT voetbal 2de plaats 🥈</p>
                        <p>IFK Judo: Gedeelde 1ste plaats overall 🥇</p>
                        <ul>
                            <li>1x 1ste plaats 🥇</li>
                            <li>3x 2de plaats 🥈🥈🥈</li>
                            <li>1x 4de plaats</li>
                        </ul>
                        <p>IFK Tafeltennis: 8ste plaats</p>
                        <p>IFT Basketbal: 7de plaats</p>
                        <p>IFT Volleybal: 7de plaats</p>
                        <p>IFK Zwemmarathon: 7de plaats</p>

                        <div className="flex flex-col lg:flex-row items-center">
                            <img className="h-64 xl:h-80" src="https://i.imgur.com/AiUzHx4.jpg" alt="" />
                            <div className="p-4 lg:p-8 lg:pl-16">
                                <h3>Last but not least:</h3>

                                <p>
                                    De skireis, elk jaar gaan we op een mega, zotte, onvergetelijke skireis naar het verre.... Frankrijk.&nbsp;
                                    <br />
                                    Hier ontdekken we de beste pistes die eindigen in de beste apr&egrave;s ski!
                                </p>
                            </div>
                        </div>

                        <div className="mt-24 text-center">
                            Sport 2023-2024: Joni Meskens (<a href="mailto:joni.meskens@hermesgent.be">joni.meskens@hermesgent.be</a>) &amp; Auguste Cloet (<a href="mailto:auguste.cloet@hermesgent.be">auguste.cloet@hermesgent.be</a>)<br /> e-mail:{" "}
                            <a href="mailto:sport@hermesgent.be">sport@hermesgent.be</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
